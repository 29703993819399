export const getSkuWithoutLensTech = (sku, partType) => {
  if (!sku) return null

  // If partType is not lenses, return the sku
  if (partType && partType !== 'lenses') return sku

  // If partType is lenses, remove the lens technology from the sku
  // Matches any of the following strings: _P, _N, _8P, _8, or _RX, and replaces them with underscore (_)
  // Matches either the string 'N' or the string '8N' at the end of the SKU, removes it if it exists
  return sku.replace(/_(P|N|8P|8|RX|STRX|STPRX|THRX|THPRX|STCRX|THCRX)/g, '_').replace(/(N|8N)$/g, '')
}

export const getLensOptionFromSku = (sku, customisedProduct) => {
  return customisedProduct.lensOptions.find((lensOption) => sku.includes(lensOption.sku))
}

export const getClosestLensTechs = (customisedProduct) => {
  const has8KO = !!customisedProduct.lensOptions.find((lensOption) => lensOption.tech === '8ko')?.tech
  const upsell = customisedProduct.lensOptions.find(
    (lensOption) => {
      switch (customisedProduct.lensChoice.optionTech.tech) {
        case '4ko': {
          return has8KO ? lensOption.tech === '8ko' : lensOption.tech === '4kop'
        }
        case '4kop': {
          return lensOption.tech === '8kop'
        }
        case '8ko': {
          return lensOption.tech === '8kop'
        }
      }
    }
  ) || {}

  const downgrade = customisedProduct.lensOptions.find(
    (lensOption) => {
      switch (customisedProduct.lensChoice.optionTech.tech) {
        case '4kop': {
          return lensOption.tech === '4ko'
        }
        case '8ko': {
          return lensOption.tech === '4ko'
        }
      }
    }
  ) || {}

  return {
    upsell,
    downgrade
  }
}

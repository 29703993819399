import gql from 'graphql-tag'

export const cartFields = gql`
  fragment cartFields on Cart {
    attributes {
      key
      value
    }
    checkoutUrl
    createdAt
    discountCodes {
      applicable
      code
    }
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
    }
    appliedGiftCards {
      lastCharacters
      amountUsed {
        amount
        currencyCode
      }
    }
    deliveryGroups(first: 10) {
      nodes {
        selectedDeliveryOption {
          estimatedCost {
            amount
            currencyCode
          }
        }
      }
    }
    totalQuantity
    buyerIdentity {
      countryCode
    }
    cost {
      totalAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      totalDutyAmount {
        amount
        currencyCode
      }
    }
    id
  }
`

export type CartFields = {
  id: string
  checkoutUrl: string
  totalQuantity: number | null
  buyerIdentity: {
    countryCode: string
  }
  attributes: {
    key: string
    value: string
  }[]
  createdAt: string
  discountCodes: {
    applicable: boolean
    code: string
  }[]
  discountAllocations: {
    discountedAmount?: {
      amount: string
      currencyCode: string
    }
  }[]
  appliedGiftCards: {
    id?: string
    lastCharacters?: string
    balance?: {
      amount: string
      currencyCode: string
    }
    amountUsed?: {
      amount: string
      currencyCode: string
    }
  }[]
  deliveryGroups: {
    nodes: {
      selectedDeliveryOption: {
        estimatedCost: {
          amount: string,
          currencyCode: string
        }
      }
    }[]
  }
  cost: {
    totalAmount: {
      amount: string
      currencyCode: string
    }
    subtotalAmount: {
      amount: string
      currencyCode: string
    }
    totalTaxAmount: {
      amount: string
      currencyCode: string
    }
    totalDutyAmount: {
      amount: string
      currencyCode: string
    }
  }
}

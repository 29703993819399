import { computed } from 'vue'

export default () => {
  const shopifyStore = useShopifyStore()
  const { $i18n } = useNuxtApp()

  const storeCountry = computed(() => {
    return $i18n.locale.value.split('-')[1].toUpperCase()
  })

  const storeCurrency = computed(() => {
    return shopifyStore.currencyCode
  })

  return { storeCurrency, storeCountry }
}

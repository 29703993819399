import { defineStore, acceptHMRUpdate } from 'pinia'
import { useShopifyClient } from '#imports'
import {
  createCartQuery,
  type CartCreateResponse,
  cartAttributesUpdate,
  type CartAttributesUpdateResponse,
  addLines,
  type CartLinesAddResponse,
  removeLines,
  type CartLinesRemoveResponse,
  updateLines,
  type CartLinesUpdateResponse,
  cartDiscountCodesUpdate,
  type CartDiscountCodesUpdateResponse,
  cartGiftCardCodesUpdate,
  type CartGiftCardCodesUpdateResponse,
} from '~/graphql/shopify/mutations/cart'

import {
  getGiftCardProduct,
  type GetGiftCardProductResponse,
} from '~/graphql/shopify/queries/product'

import type { AutomaticDiscount } from '~/graphql/shopify/queries/automaticDiscounts'
import { getCart } from '~/graphql/shopify/queries/cart'
import countries from '~/assets/constants/shopify/countries.json'

import type { Cart, CartResponse } from '~/graphql/shopify/fragments/cart'
import type { CartLine } from '~/graphql/shopify/fragments/cartLines'

import {
  getCouponCode,
  getAllValidGiftDiscounts,
  isValidGiftCard,
  buildLineIdsToRemove,
  buildCartItemsWithBundleDisplayOrder,
  getBundleDisplayOrder,
  getBundleSkuFromBundleItems,
  getBundleCheckoutInfo,
  getBundleDespatchDate,
  getBundleSkusFromBundleItems,
  getBundleBaseSkuFromBundleItems,
  getBundleCost,
  getOriginalBundleCost,
  getBundleQuantity,
  getExpectedCartAttributes,
  captureException,
  getAllDiscountsFromProduct,
  getDiscountFromCartItems,
  getUniqueBundleIdsFromNewLineItems,
  getExistingBundlesInCartFromSku,
  getBundleIsGiftCard,
  getBundleUpsellItem,
  getBundleIsPrescription,
  type CartIDRecord,
  type CartAttributes,
  type Bundle,
  type CouponResponse,
  type GiftCard,
} from '~/assets/js/helpers/shopify'

export const useShopifyStore = defineStore('shopify', {
  state: () => ({
    locale: useRoute().params.locale,
    cartIds: [] as CartIDRecord[],
    cart: {
      id: '',
      checkoutUrl: '',
      totalQuantity: null,
      buyerIdentity: {
        countryCode: '',
      },
      cost: {
        subtotalAmount: {
          amount: '',
          currencyCode: '',
        },
        totalAmount: {
          amount: '',
          currencyCode: '',
        },
        totalDutyAmount: {
          amount: '',
          currencyCode: '',
        },
        totalTaxAmount: {
          amount: '',
          currencyCode: '',
        },
        checkoutChargeAmount: {
          amount: '',
          currencyCode: '',
        },
      },
      deliveryGroups: {
        nodes: [],
      },
      lines: {
        edges: [],
      },
      attributes: [],
      discountCodes: [],
      discountAllocations: [],
      appliedGiftCards: [],
      createdAt: '',
    } as Cart,
    automaticDiscounts: [] as AutomaticDiscount[],
    couponDetails: {} as any,
    loading: false,
    fetched: false,
    optimisticCartCount: 0 as any,
    open: false,
  }),

  getters: {
    country(state) {
      let locale = state.locale || 'en-gb'

      if (Array.isArray(locale)) locale = locale[0]

      const countryCode = locale.split('-')[1].toUpperCase()
      const country =
        countries.find((c) => c.isoCode === countryCode) || countries[0]

      return country
    },

    cartId(): string | undefined {
      const marketId = this.country.market.id?.split('/').pop()
      const record = this.cartIds.find((r) => r.marketId === marketId)
      return record?.cartId
    },

    currencyCode(): string {
      if (!this.cart.totalQuantity) {
        // Until there are items in the cart it will report the incorrect
        // currency. Use the shop currency instead
        return this.country.currency.isoCode
      }

      return this.cart.cost.totalAmount.currencyCode
    },

    bundledCartItems(state): { [key: string]: Bundle } {
      if (!state.cart.totalQuantity) return {}

      const bundledCartItems = {} as { [key: string]: Bundle }

      this.cart.lines.edges.forEach((cartItem) => {
        if (cartItem.node.lineComponents?.length) {
          // This has been bundled by Shopify
          bundledCartItems[cartItem.node.id] = {
            isShopifyBundle: true,
            bundleId: cartItem.node.id,
            items: [
              cartItem,
              ...cartItem.node.lineComponents.map((component) => ({
                node: component,
              })),
            ] as { node: CartLine }[],
          }
        } else {
          let bundleId = cartItem.node.attributes.find(
            (attribute) => attribute.key === '_bundleId'
          )?.value

          if (!bundleId) bundleId = cartItem.node.id

          if (!bundledCartItems[bundleId]) {
            bundledCartItems[bundleId] = {
              isShopifyBundle: false,
              bundleId,
              items: [],
            }
          }

          bundledCartItems[bundleId].items.push(cartItem)
        }
      })

      Object.entries(bundledCartItems).forEach(([bundleId, bundle]) => {
        bundledCartItems[bundleId].summary = {
          bundleSku: getBundleSkuFromBundleItems(bundle.items),
          bundleSkus: getBundleSkusFromBundleItems(bundle.items),
          bundleBaseSku: getBundleBaseSkuFromBundleItems(bundle.items),
          bundleCost: getBundleCost(bundle),
          bundleOriginalCost: getOriginalBundleCost(bundle),
          bundleCostDifference:
            getBundleCost(bundle) - getOriginalBundleCost(bundle),
          bundleQuantity: getBundleQuantity(bundle.items),
          bundleDisplayOrder: getBundleDisplayOrder(bundle.items),
          bundleCheckoutInfo: getBundleCheckoutInfo(bundle.items),
          bundleDespatchDate: getBundleDespatchDate(bundle.items),
          bundleIsGiftCard: getBundleIsGiftCard(bundle.items),
          bundleIsUpsoldItem: getBundleUpsellItem(bundle.items),
          bundleIsPrescription: getBundleIsPrescription(bundle.items),
        }
      })

      return bundledCartItems
    },

    upsoldItems(): any {
      return Object.entries(this.bundledCartItems)
        .filter(
          ([_bundleId, bundle]) => bundle.summary.bundleIsUpsoldItem === true
        )
        .map(([_bundleId, bundle]) => bundle)
    },

    baseSkusInCart(): any {
      return Object.entries(this.bundledCartItems).map(
        ([_bundleId, bundle]) => bundle.summary.bundleBaseSku
      )
    },

    bundledCartItemsTotal(state) {
      return state.cart.totalQuantity
    },

    getOptimisticCartCount(state): Number {
      return state.optimisticCartCount !== this.bundledCartItemsTotal
        ? state.optimisticCartCount
        : this.bundledCartItemsTotal
    },

    freeShippingPromo(): AutomaticDiscount | undefined {
      let freeShippingPromo: AutomaticDiscount | undefined

      // First attempt to find a shipping promo for a specific country
      const freeShippingPromoForCountry: AutomaticDiscount | undefined =
        this.automaticDiscounts.find((automaticDiscount) => {
          if (
            automaticDiscount.automaticDiscount.discountClass === 'SHIPPING' &&
            automaticDiscount.automaticDiscount.destinationSelection?.type ===
              'DiscountCountries'
          ) {
            if (
              automaticDiscount.automaticDiscount.destinationSelection.countries
                ?.length
            ) {
              return automaticDiscount.automaticDiscount.destinationSelection.countries.includes(
                this.locale.split('-')[1].toUpperCase()
              )
            }

            return true
          }
        })

      // If there is no promo for a country then just get the first free shipping promo
      if (!freeShippingPromoForCountry) {
        freeShippingPromo = this.automaticDiscounts.find(
          (automaticDiscount) =>
            automaticDiscount.automaticDiscount.discountClass === 'SHIPPING' &&
            automaticDiscount.automaticDiscount.destinationSelection?.type ===
              'DiscountCountryAll'
        )
      } else {
        freeShippingPromo = freeShippingPromoForCountry
      }

      // Add additional pre-calculated fields to the promo based on other field values
      if (freeShippingPromo) {
        freeShippingPromo.threshold = Number(
          freeShippingPromo.automaticDiscount?.minimumRequirement
            ?.greaterThanOrEqualToSubtotal?.amount || 0
        )

        freeShippingPromo.remainingSpend =
          freeShippingPromo.threshold -
          Number(this.cart.cost.subtotalAmount.amount)

        freeShippingPromo.thresholdMet = freeShippingPromo.remainingSpend <= 0
      }

      return freeShippingPromo
    },

    getDiscountsFromProduct() {
      return (customisedProduct: any) => {
        return getAllDiscountsFromProduct(
          customisedProduct,
          getAllValidGiftDiscounts(this.automaticDiscounts),
          'customerBuys'
        )
      }
    },

    getDiscountsForProductFromCartItems() {
      return (customisedProduct: any) => {
        return getDiscountFromCartItems(
          customisedProduct,
          this.bundledCartItems,
          this.automaticDiscounts
        )
      }
    },

    couponCodes(state) {
      return state.cart.discountCodes.map((discount) => discount.code)
    },

    totalShippingCost(state): number {
      return (
        Number(
          state.cart.deliveryGroups.nodes[0]?.selectedDeliveryOption
            ?.estimatedCost?.amount
        ) || 0
      )
    },

    totalDiscount(state): number {
      const discountAmounts = state.cart.discountAllocations.map(
        (discount: any) => Number(discount.discountedAmount.amount)
      )
      // Filter out discounts that exactly match the cost of shipping
      const filteredDiscounts = discountAmounts.filter(
        (discount) => discount !== this.totalShippingCost
      )
      return filteredDiscounts.reduce((sum, number) => sum + number, 0)
    },

    hasFreeShippingDiscount(state) {
      const discountAmounts = state.cart.discountAllocations.map(
        (discount: any) => Number(discount.discountedAmount.amount)
      )
      return discountAmounts.some(
        (discount) => discount === this.totalShippingCost
      )
    },

    giftCard(state): GiftCard {
      return state.cart.appliedGiftCards[0]
    },

    existingBundlesInCartFromSku() {
      return (sku: string) => {
        return getExistingBundlesInCartFromSku(this.bundledCartItems, sku)
      }
    },
  },

  actions: {
    async createCart() {
      this.loading = true

      try {
        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<CartCreateResponse>(
          'create-cart',
          createCartQuery,
          { variables: { attributes: getExpectedCartAttributes() } }
        )

        if (response?.cartCreate?.cart) {
          this.cart = response.cartCreate.cart
        }

        const marketId = this.country.market.id?.split('/').pop() as string
        this.cartIds = [
          ...this.cartIds.filter((r) => r.marketId !== marketId),
          { marketId, cartId: this.cart.id },
        ]
      } catch (error) {
        captureException(error as Error)
      } finally {
        this.loading = false
      }
    },

    async getCart() {
      if (!this.cartId) {
        await this.createCart()
      }

      try {
        this.loading = true

        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<CartResponse>(
          'get-cart',
          getCart,
          { variables: { id: this.cartId } }
        )

        if (response?.cart) {
          this.cart = response.cart
          this.optimisticCartCount = this.cart.totalQuantity || 0
        }

        this.fetched = true

        await this.validateCart()
        await this.validateCartAttributes()
      } catch (error) {
        captureException(error as Error)
      } finally {
        this.loading = false
      }
    },

    async validateCart() {
      let isValid = true

      // If the countryCode of this cart doesn't match the
      // current country, we need to create a new cart
      if (this.country.isoCode !== this.cart?.buyerIdentity?.countryCode) {
        console.error(
          `Cart country code ${this.cart?.buyerIdentity?.countryCode} does not match current country ${this.country.isoCode}`
        )
        isValid = false
      }

      if (!isValid) {
        await this.createCart()
      }
    },

    async validateCartAttributes() {
      // Check if the cart has the expected attributes
      const expectedAttributes = getExpectedCartAttributes()
      const cartAttributes = this.cart.attributes

      const hasExpectedAttributes = expectedAttributes.every((expected) => {
        return cartAttributes.some(
          (attribute) =>
            attribute.key === expected.key && attribute.value === expected.value
        )
      })

      if (!hasExpectedAttributes) {
        await this.updateCartAttributes(expectedAttributes)
      }
    },

    async updateCartAttributes(attributes: CartAttributes) {
      if (!this.cartId) await this.createCart()

      try {
        this.loading = true

        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<CartAttributesUpdateResponse>(
          'update-cart-attributes',
          cartAttributesUpdate,
          {
            variables: {
              cartId: this.cartId,
              attributes,
            },
          }
        )

        if (response?.cartAttributesUpdate?.cart) {
          this.cart = response.cartAttributesUpdate.cart
        }
      } catch (error) {
        captureException(error as Error)
      } finally {
        this.loading = false
      }
    },

    async addCartItems(
      newLines: {
        merchandiseId: string
        quantity: number
        attributes: {
          key: string
          value: string
        }
      }[],
      updateCartState: boolean = true,
      updateOptimisticCount: boolean = true
    ) {
      if (updateOptimisticCount) {
        this.optimisticCartCount =
          this.optimisticCartCount +
          (getUniqueBundleIdsFromNewLineItems(newLines).length
            ? getUniqueBundleIdsFromNewLineItems(newLines).length
            : 1)
      }

      if (!this.cartId) await this.createCart()

      try {
        this.loading = true

        const newLinesWithBundleDisplayOrder =
          buildCartItemsWithBundleDisplayOrder(this.bundledCartItems, newLines)

        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<CartLinesAddResponse>(
          'add-items-to-cart',
          addLines,
          {
            variables: {
              cartId: this.cartId,
              lines: newLinesWithBundleDisplayOrder,
            },
          }
        )

        // Store cart if it is defined
        if (response?.cartLinesAdd?.cart && updateCartState) {
          this.cart = response.cartLinesAdd.cart
          this.optimisticCartCount = this.cart.totalQuantity || 0

          this.fetched = true
        }

        return response
      } catch (error) {
        captureException(error as Error)
      } finally {
        this.loading = false
      }
    },

    async removeCartItems(
      productsToRemove: string[],
      updateCartState: boolean = true,
      updateOptimisticCart: boolean = true
    ) {
      if (!this.cartId) return
      if (updateOptimisticCart) {
        this.optimisticCartCount =
          this.optimisticCartCount - productsToRemove.length
      }

      if (!Array.isArray(productsToRemove)) {
        captureException(
          new Error('productsToRemove should be an array of SKUs')
        )
        return
      }

      const lineIdsToRemove = buildLineIdsToRemove(
        productsToRemove,
        this.bundledCartItems
      )

      if (!lineIdsToRemove.length) {
        captureException(
          new Error(
            `Could not find matching bundle item to remove based on passed in SKU(s) ${productsToRemove}`
          )
        )
        return
      }

      try {
        this.loading = true

        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<CartLinesRemoveResponse>(
          'remove-lines',
          removeLines,
          {
            variables: {
              cartId: this.cartId,
              lineIds: lineIdsToRemove,
            },
          }
        )

        // Store cart if it is defined
        if (response?.cartLinesRemove?.cart && updateCartState) {
          this.cart = response.cartLinesRemove.cart
          this.optimisticCartCount = this.cart.totalQuantity || 0
        }

        return response
      } catch (error) {
        captureException(error as Error)
      } finally {
        this.loading = false
      }
    },

    async updateCartItems(
      lines:
        | {
            id: string
            merchandiseId?: string
            quantity: number
          }
        | { id: string; quantity: number }[]
    ) {
      if (!this.cartId) return

      try {
        this.loading = true

        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<CartLinesUpdateResponse>(
          'update-lines',
          updateLines,
          {
            variables: {
              cartId: this.cartId,
              lines,
            },
          }
        )

        // Store cart if it is defined
        if (response?.cartLinesUpdate?.cart) {
          this.cart = response.cartLinesUpdate.cart
          this.optimisticCartCount = this.cart.totalQuantity || 0
        }

        return response
      } catch (error) {
        captureException(error as Error)
      } finally {
        this.loading = false
      }
    },

    async swapCartItems(
      itemsToAdd: {
        merchandiseId: string
        quantity: number
      }[],
      productsToRemove: string[]
    ) {
      if (!this.cartId) return

      try {
        this.loading = true

        await this.removeCartItems(productsToRemove, false, false)
        return await this.addCartItems(itemsToAdd, true, false)
      } catch (error) {
        captureException(error as Error)
      } finally {
        this.loading = false
      }
    },

    async getAutomaticDiscounts() {
      console.time('-> getAutomaticDiscounts')
      const { data, error } = await useFetch<AutomaticDiscount[]>(
        '/api/' + useRoute().params.locale + '/promotions'
      )
      console.timeEnd('-> getAutomaticDiscounts')

      if (data.value) {
        this.automaticDiscounts = data.value
      }

      if (error.value) {
        captureException(error.value as Error)
        return
      }
    },

    async updateCouponCodes(codes: string[]): Promise<CouponResponse> {
      try {
        this.loading = true

        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<CartDiscountCodesUpdateResponse>(
          'update-discounts',
          cartDiscountCodesUpdate,
          {
            variables: {
              cartId: this.cartId,
              discountCodes: codes,
            },
          }
        )

        if (response?.cartDiscountCodesUpdate?.cart) {
          this.cart = response.cartDiscountCodesUpdate.cart
        } else {
          throw new Error('Unable to update discount codes')
        }
        return { success: true, body: response }
      } catch (error) {
        captureException(error as Error)
        return { success: false }
      } finally {
        this.loading = false
      }
    },

    async applyCouponCode({
      code,
      silent = false,
    }: {
      code: string
      silent?: boolean
    }) {
      const alertStore = useAlertStore()
      if (!this.cartId) return { success: false, error: 'no-cart' }

      // Check if coupon is a gift card first
      const isGiftCard = await isValidGiftCard(code)
      if (isGiftCard) {
        const giftCard = await this.updateGiftCard({ code })
        return { success: giftCard.success }
      }

      const isValidCode = await this.validateCouponCode(code)
      if (!isValidCode) {
        alertStore.ADD_ERROR_MESSAGE({
          body: 'Invalid coupon code. Please try again.',
        })
        return {
          success: false,
          error: 'invalid',
        }
      }

      try {
        const response = await this.updateCouponCodes([code])

        if (!response) {
          throw new Error(`Unable to add discount code: ${code}`)
        }

        if (response && this.couponCodes.includes(code)) {
          if (!silent) {
            alertStore.ADD_MESSAGE({
              body: `Coupon code ${code} successfully applied.`,
            })
          }
          return { body: response, success: true }
        }
      } catch (error) {
        alertStore.ADD_ERROR_MESSAGE({
          body: 'Something went wrong. Please try again.',
        })
        captureException(error as Error)
        return { success: false, error: `${error}` }
      }
    },

    async removeCouponCode({
      code,
      silent = false,
    }: {
      code: string
      silent?: boolean
    }) {
      const alertStore = useAlertStore()

      if (!this.couponCodes.includes(code)) {
        console.error(`Discount code ${code} not currently applied`)
        return { success: false }
      }

      this.loading = true

      try {
        const response = await this.updateCouponCodes([])

        if (response && !this.couponCodes.includes(code)) {
          if (!silent) {
            alertStore.ADD_MESSAGE({
              body: `Coupon code ${code} successfully removed.`,
            })
          }
          this.loading = false
          return { success: true }
        } else {
          alertStore.ADD_ERROR_MESSAGE({
            body: `We were unable to remove that code, please try again.`,
          })
          this.loading = false
          return { success: false }
        }
      } catch (error) {
        alertStore.ADD_ERROR_MESSAGE({
          body: `Something went wrong, please try again.`,
        })
        captureException(error as Error)
        this.loading = false
        return { success: false }
      }
    },

    async validateCouponCode(code: string): Promise<boolean> {
      if (this.couponDetails[code]?.id) return true
      const coupon = await getCouponCode(code)
      // Store the coupon if it is valid
      if (coupon?.id) {
        this.couponDetails = {
          ...this.couponDetails,
          [code]: coupon,
        }
      }
      return coupon?.id ? true : false
    },

    async updateGiftCard({
      code,
      silent = true,
    }: {
      code: string
      silent?: boolean
    }): Promise<couponResponse> {
      const alertStore = useAlertStore()
      try {
        this.loading = true

        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<CartGiftCardCodesUpdateResponse>(
          'update-giftcards',
          cartGiftCardCodesUpdate,
          {
            variables: {
              cartId: this.cartId,
              giftCardCodes: [code],
            },
          }
        )
        if (response?.cartGiftCardCodesUpdate?.cart) {
          this.cart = response.cartGiftCardCodesUpdate.cart
          if (!silent) {
            alertStore.ADD_MESSAGE({
              body: `Gift card successfully updated`,
            })
          }
          return { success: true }
        } else {
          throw new Error('Unable to update gift cards')
        }
      } catch (error) {
        alertStore.ADD_ERROR_MESSAGE({
          body: `Something went wrong, please try again.`,
        })
        captureException(error as Error)
        return { success: false }
      } finally {
        this.loading = false
      }
    },

    async getGiftCardProduct(commerceId: string) {
      try {
        const { shopifyRequest } = useShopifyClient()
        const response = await shopifyRequest<GetGiftCardProductResponse>(
          'get-gift-card-product',
          getGiftCardProduct,
          { variables: { id: commerceId, country: 'US' } }
        )

        if (response?.product?.variants?.nodes?.length) {
          return {
            giftCardOptions: response.product.variants.nodes.map(
              (option) => option
            ),
          }
        }
      } catch (error) {
        captureException(error as Error)
      }
    },

    toggleCart(boolean: boolean) {
      this.open = boolean
    },
  },

  persist: {
    paths: ['cartIds', 'optimisticCartCount'],
    storage: persistedState.localStorage,
  },

  hydrate(state) {
    state.locale = useRoute().params.locale
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopifyStore, import.meta.hot))
}

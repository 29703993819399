import {
  imgproxySettings,
  mobileImgproxySettings
} from './shared.mjs'

import { lenses, engraving } from './miras.mjs'

export const ghost = {
  id: 'ghost',
  name: 'Ghost Frame',
  hasOptions: false,
  imgproxySettings,
  mobileImgproxySettings,
  opacity: 0.5,
  pathTemplate: 'gs://sg360/240716/Miras/LRK-white-{nnn}.png',
  staticPathTemplate: 'gs://sg360/240716/Miras/LRK-white-{nnn}.png'
}

export function resolveOptions (customisedProduct) {
  const engraving = {
    '4ko': 'eN',
    '4kop': 'eP',
    '8ko': 'e8',
    '8kop': 'e8P'
  }[customisedProduct.lensTech] || 'eN'

  return {
    lens: customisedProduct.lensChoice.skuWithoutLensTech.split('_')?.[1],
    engraving
  }
}

export function resolveOptionsFromSKU (sku) {
  const lensSKUPrefixes = ['mle']
  
  const getEngraving = (choice) => {
    const engravings = [
      [/^N/g, 'eN'],
      [/^P/g, 'eP'],
      [/^8(?!P|IR)/g, 'e8'],
      [/^8P/g, 'e8P']
    ]
    return (engravings.find(
      ([pattern, _]) => choice.match(pattern)
    ) || engravings[0])[1]
  }

  const parts = sku.split('-').slice(1)
  return Object.fromEntries(parts.map((part) => {
    const [skuPrefix, choice] = part.split('_')
    if (lensSKUPrefixes.includes(skuPrefix)) return [
      ['lens', choice.replace(/^(P|N|8P|8)/g, '')],
      ['engraving', getEngraving(choice)]
    ]
    
    return null
  }).filter(choice => choice).flat())
}

export default {
  supports360: false,
  layers: [
    ghost,
    lenses,
    engraving
  ],
  resolveOptions,
  resolveOptionsFromSKU
}
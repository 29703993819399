import gql from 'graphql-tag'

export const productFields = gql`
  fragment productFields on Product {
    id
    title
    tags
    isGiftCard
    handle
  }
`

export type ProductFields = {
  id: string
  title: string
  tags: string[]
  isGiftCard: boolean
  description: string
  descriptionHtml: string
  handle: string
}

export const productVariantFields = gql`
  fragment productVariantFields on ProductVariant {
    id
    sku
    availableForSale
    quantityAvailable
    currentlyNotInStock
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
    inboundDeliveryDate: metafield(namespace: "custom", key: "next_delivery_date") {
      value
    }
  }
`

export type ProductVariantFields = {
  id: string
  sku: string
  title: string
  availableForSale: boolean
  quantityAvailable: number
  currentlyNotInStock: boolean
  price: {
    amount: number
    currencyCode: string
  }
  prices?: {
    [currency: string]: number
  }
  compareAtPrice: {
    amount: number
    currencyCode: string
  } | null
  compareAtPrices?: {
    [currency: string]: number
  } | null
  inboundDeliveryDate: {
    value: string
  }
}
import gql from 'graphql-tag'
import { cart } from '~/graphql/shopify/fragments/cart'

export const getCart = gql`
  query getCart($country: CountryCode!, $id: ID!)
  @inContext(country: $country) {
    cart(id: $id) {
      ...cart
    }
  }
  ${cart}
`

export const getPaymentMethods = gql`
  query getPaymentMethods {
    shop {
      paymentSettings {
        acceptedCardBrands
        supportedDigitalWallets
      }
    }
  }
`

export type PaymentMethodsResponse = {
  shop: {
    paymentSettings: {
      acceptedCardBrands: String[]
      supportedDigitalWallets: String[]
    }
  }
}

const getPayload = model => ({
  ...model,
  amount: (model.interestLevel || '').replace(/£/g, '').replace(/ /g, '').replace(/k/g, '000')
})

export default ra => ({
  async submit (model) {
    await ra.track('Investor Form Submitted', getPayload(model))
  },

  async progress (model) {
    await ra.track('Investor Form Progress', getPayload(model))
  }
})